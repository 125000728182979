import React, {useState} from 'react';
import { Container, NavItem, NavLink, Navbar, Collapse,
    NavbarToggler,
    NavbarBrand,
    Nav,UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText, Dropdown
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MasterContext from "./Misc/MasterContext";
import Login from './Login/Login';
import SsoLogin from './Login/SsoLogin';
import LoginService from '../../Services/Login/Service/Login.service';
import './css/normalize.css'
import './css/site-layout.css'

export class IProps {
    refreshLogin: Function;
    checkingSso: boolean;
    
}
export class IState {
    forecasts: any[];
    loading: boolean;
    collapsed: boolean;
    reports: any[];
    ssoEnabled: boolean;
    ssoRedirectUrl: string;
    checkingSso: boolean;
    backdoor: boolean;
    isOpen: boolean;

    dropdownOpenManage: boolean;
    dropdownOpenRegular: boolean;
    dropdownOpenPromo: boolean;
    dropdownOpenEngine: boolean;
    dropdownOpenReport: boolean;
    dropdownOpenAdmin: boolean;
    dropdownOpenSetting: boolean;

    dropdownOpenManagePG: boolean;
    dropdownOpenManageLG: boolean;
    dropdownOpenManageS: boolean;
    dropdownOpenPromEvent: boolean;
    dropdownOpenPromTemp: boolean;
    dropdownOpenPromAd: boolean;

    dropdownOpenAdminSet: boolean;
    dropdownOpenAdminUser: boolean;
    dropdownOpenAdminAudit: boolean;
} 
export class Layout extends React.Component<IProps, IState> {

    static displayName = Layout.name;
    constructor(props: any) {
        super(props);
        this.state = {
            forecasts: [],
            loading: true,
            collapsed: true,
            reports: [],
            ssoEnabled: false,
            ssoRedirectUrl: '',
            checkingSso: this.props.checkingSso,
            isOpen: false,
            backdoor: window.location.hash.includes("backdoor=true"),
            dropdownOpenManage: false,
            dropdownOpenRegular: false,
            dropdownOpenPromo: false,
            dropdownOpenEngine: false,
            dropdownOpenReport: false,
            dropdownOpenAdmin: false,
            dropdownOpenSetting: false,
            dropdownOpenManagePG: false,            
            dropdownOpenManageLG: false,
            dropdownOpenManageS: false,
            dropdownOpenPromEvent: false,
            dropdownOpenPromTemp: false,
            dropdownOpenPromAd: false,
            dropdownOpenAdminSet: false,
            dropdownOpenAdminUser: false,
            dropdownOpenAdminAudit: false
        };
        this.toggleNav = this.toggleNav.bind(this);
        this.onMouseEnterNav = this.onMouseEnterNav.bind(this);
        this.onMouseLeaveNav = this.onMouseLeaveNav.bind(this);



        this.refreshLogin = this.refreshLogin.bind(this);
        this.registerReport = this.registerReport.bind(this);
        this.getReports = this.getReports.bind(this);

        this.toggleNavbar = this.toggleNavbar.bind(this);
    }
    
    registerReport(reportName: string) {
        MasterContext.TableauReportSREF = reportName;
    }
    
    getReports() {
        let retVal: any[] = [];

        for (let itm of this.state.reports) {
            if (itm.sref === null && itm.items) {
                for (let itm2 of itm.items) {
                    retVal.push(
                        <DropdownItem>
                            <NavLink tag={Link} style={{ marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px' }} to="/tableauhost"><span onClick={() => { this.registerReport(itm2.sref) }} className="dropdown-item" >{itm2.label}</span></NavLink>
                        </DropdownItem>
                    );
                }                
            }
            else {
                retVal.push(
                    <DropdownItem>
                        <NavLink tag={Link} style={{ marginLeft: '-8px', marginTop: '-10px', marginBottom: '-10px' }} to="/tableauhost"><span onClick={() => { this.registerReport(itm.sref) }} className="dropdown-item" >{itm.label}</span></NavLink>
                    </DropdownItem>
                );
            }

        }
        return retVal;
    }
    componentDidMount() {
        this.getSsoRedirectUrl();
        this.getSsoEnabled();

        MasterContext.TableauService.getReports((e) => {
            this.setState({ reports: e[0].items }, () => {
            })
        }, () => { })        

        this.setState({ loading: false });
    }

    refreshLogin() {

        this.forceUpdate();
        this.props.refreshLogin();
    }

    getLoginState(): boolean {
        let retVal = false;

        if (MasterContext.UserData.userName !== '') {
            retVal = true;
        }

        return retVal;
    }

    getSsoEnabled() {
        let LS: LoginService = new LoginService();
        LS.ssoEnabled(() => {
            this.setState({ ssoEnabled: true, checkingSso: false });
        }, () => {
            this.setState({ checkingSso: false })
        });
    }

    getSsoRedirectUrl() {
        let LS: LoginService = new LoginService();
        LS.ssoRedirectUrl((resp) => {
            this.setState({ ssoRedirectUrl: resp });
            console.log(`redirect url: ${resp}`);
        });
    }

    getUserName(): string {
        let retVal = '';

        retVal = MasterContext.UserData.userName;

        return retVal;
    }

    setIsOpen(open) {
        this.setState({ isOpen: open });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }  

    onMouseEnterNav(navItem) {
        switch (navItem) {
            case 'Manage':
                this.setState({ dropdownOpenManage: true });
                break;
            case 'ManagePG':
                this.setState({ dropdownOpenManagePG: true });
                break;
            case 'ManageLG':
                this.setState({ dropdownOpenManageLG: true });
                break;
            case 'ManageS':
                this.setState({ dropdownOpenManageS: true });
                break;
            case 'Regular':
                this.setState({ dropdownOpenRegular: true });
                break;
            case 'Promo':
                this.setState({ dropdownOpenPromo: true });
                break;
            case 'PromEvent':
                this.setState({ dropdownOpenPromEvent: true });
                break;
            case 'PromTemp':
                this.setState({ dropdownOpenPromTemp: true });
                break;
            case 'PromAd':
                this.setState({ dropdownOpenPromAd: true });
                break;
            case 'Engine':
                this.setState({ dropdownOpenEngine: true });
                break;
            case 'Report':
                this.setState({ dropdownOpenReport: true });
                break;
            case 'Admin':
                this.setState({ dropdownOpenAdmin: true });
                break;
            case 'AdminSet':
                this.setState({ dropdownOpenAdminSet: true });
                break;
            case 'AdminUser':
                this.setState({ dropdownOpenAdminUser: true });
                break;
            case 'AdminAudit':
                this.setState({ dropdownOpenAdminAudit: true });
                break;
            case 'Setting':
                this.setState({ dropdownOpenSetting: true });
                break;
        }
    }

    onMouseLeaveNav(navItem) {
        switch (navItem) {
            case 'Manage':
                this.setState({ dropdownOpenManage: false });
                break;
            case 'ManagePG':
                this.setState({ dropdownOpenManagePG: false });
                break;
            case 'ManageLG':
                this.setState({ dropdownOpenManageLG: false });
                break;
            case 'ManageS':
                this.setState({ dropdownOpenManageS: false });
                break;
            case 'Regular':
                this.setState({ dropdownOpenRegular: false });
                break;
            case 'Promo':
                this.setState({ dropdownOpenPromo: false });
                break;
            case 'PromEvent':
                this.setState({ dropdownOpenPromEvent: false });
                break;
            case 'PromTemp':
                this.setState({ dropdownOpenPromTemp: false });
                break;
            case 'PromAd':
                this.setState({ dropdownOpenPromAd: false });
                break;
            case 'Engine':
                this.setState({ dropdownOpenEngine: false });
                break;
            case 'Report':
                this.setState({ dropdownOpenReport: false });
                break;
            case 'Admin':
                this.setState({ dropdownOpenAdmin: false });
                break;
            case 'AdminSet':
                this.setState({ dropdownOpenAdminSet: false });
                break;
            case 'AdminUser':
                this.setState({ dropdownOpenAdminUser: false });
                break;
            case 'AdminAudit':
                this.setState({ dropdownOpenAdminAudit: false });
                break;
            case 'Setting':
                this.setState({ dropdownOpenSetting: false });
                break;
        }
    }

    toggleNav(navItem) {
        switch (navItem) {
            case 'Manage':
                this.setState(prevState => ({dropdownOpenManage: !prevState.dropdownOpenManage}));
                break;
            case 'ManagePG':
                this.setState(prevState => ({
                    dropdownOpenManagePG: !prevState.dropdownOpenManagePG,
                    dropdownOpenManage: !prevState.dropdownOpenManage
                }));
                break;
            case 'ManageLG':
                this.setState(prevState => ({
                    dropdownOpenManageLG: !prevState.dropdownOpenManageLG,
                    dropdownOpenManage: !prevState.dropdownOpenManage
                }));
                break;
            case 'ManageS':
                this.setState(prevState => ({
                    dropdownOpenManageS: !prevState.dropdownOpenManageS,
                    dropdownOpenManage: !prevState.dropdownOpenManage
                }));
                break;
            case 'Regular':
                this.setState(prevState => ({dropdownOpenRegular: !prevState.dropdownOpenRegular}));
                break;
            case 'Promo':
                this.setState(prevState => ({ dropdownOpenPromo: !prevState.dropdownOpenPromo}));
                break;
            case 'PromEvent':
                this.setState(prevState => ({
                    dropdownOpenPromEvent: !prevState.dropdownOpenPromEvent,
                    dropdownOpenPromo: !prevState.dropdownOpenPromo
                }));
                break;
            case 'PromTemp':
                this.setState(prevState => ({
                    dropdownOpenPromTemp: !prevState.dropdownOpenPromTemp,
                    dropdownOpenPromo: !prevState.dropdownOpenPromo
                }));
                break;
            case 'PromAd':
                this.setState(prevState => ({
                    dropdownOpenPromAd: !prevState.dropdownOpenPromAd,
                    dropdownOpenPromo: !prevState.dropdownOpenPromo
                }));
                break;
            case 'Engine':
                this.setState(prevState => ({ dropdownOpenEngine: !prevState.dropdownOpenEngine }));
                break;
            case 'Report':
                this.setState(prevState => ({dropdownOpenReport: !prevState.dropdownOpenReport}));
                break;
            case 'Admin':
                this.setState(prevState => ({dropdownOpenAdmin: !prevState.dropdownOpenAdmin}));
                break;
            case 'AdminSet':
                this.setState(prevState => ({
                    dropdownOpenAdminSet: !prevState.dropdownOpenAdminSet,
                    dropdownOpenAdmin: !prevState.dropdownOpenAdmin
                }));
                break;
            case 'AdminUser':
                this.setState(prevState => ({
                    dropdownOpenAdminUser: !prevState.dropdownOpenAdminUser,
                    dropdownOpenAdmin: !prevState.dropdownOpenAdmin
                }));
                break;
            case 'AdminAudit':
                this.setState(prevState => ({
                    dropdownOpenAdminAudit: !prevState.dropdownOpenAdminAudit,
                    dropdownOpenAdmin: !prevState.dropdownOpenAdmin
                }));
                break;
            case 'Setting':
                this.setState(prevState => ({dropdownOpenSetting: !prevState.dropdownOpenSetting}));
                break;
        }
    }

    render() {

        const styles = {
            alignCenter: { textAlign: 'center' },
            alignLeft: { textAlign: 'left', marginTop: '-3px' },
            nobackgroundAlignCenter: { backgroundColor: 'unset', color: 'black', width: '100%', borderColor: 'white', textAlign: 'left', textIndent: '43px' },
            minWidth: { minWidth: '14em', marginTop: '-2px' },
            navbarNavText: { fontFamily: 'Roboto, Tahoma, Geneva, sans-serif', fontSize: '19px', marginRight: '20px !important' },
            containerMinHeight: { minHeight: '84vh' },
            noBorder: { borderTop: '1px solid black', minWidth: '14rem', marginLeft: '-2px' }
        } as const;

        if (this.state.checkingSso) {
            return (
                 <div className="loginBody">
                    <div className="row" style={{ width: "100%", padding: "0 0 45px 0" }}>
                        <div className="col-md-12 text-center">
                            <img alt='' src="https://cleardemand.com/wp-content/uploads/2023/08/loginLogo.png" /> <br />
                            <br />
                            <br />
                            <h2>Determining Login Method<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h2>
                        </div>
                        
                    </div>
                </div>
            );
        }

        else if (this.state.ssoEnabled && !this.getLoginState() && !this.state.backdoor) {
            return (
                <SsoLogin handleLogin={this.refreshLogin} ssoRedirectUrl={this.state.ssoRedirectUrl} />
            );
        }
        else if (!this.getLoginState()) {
            return (
                <Login handleLogin={this.refreshLogin} />
            );
        }
        
        return (
            <div>
                <Navbar className="navbar navbar-expand-md navbar-light bg-light" expand={false}>
                    <NavbarBrand href="/"><img alt="" src="core/img/logo.png" style={{ width: '343px' }}></img></NavbarBrand>  
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="container-fluid" navbar>
                            {/*<!--<UncontrolledDropdown nav inNavbar>-->*/}
                            <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Manage')} onMouseLeave={()=>this.onMouseLeaveNav('Manage')} isOpen={this.state.dropdownOpenManage} toggle={()=>this.toggleNav('Manage')}>
                                <DropdownToggle nav caret>
                                    <span style={styles.navbarNavText}>Manage</span>
                                    </DropdownToggle>
                                <DropdownMenu left style={styles.alignLeft}>
                                    {MasterContext.hasNavigationPermission('Manage > Rules') ?
                                        <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/managerules"><span className="dropdown-item" > {MasterContext.translate('nav_menu_manage_rules')}</span></NavLink></DropdownItem>
                                        : null}
                                    {MasterContext.hasNavigationParentPermission('Manage > Product Groups') ?
                                        <Dropdown direction="right" onMouseOver={() => this.onMouseEnterNav('ManagePG')} onMouseLeave={() => this.onMouseLeaveNav('ManagePG')} isOpen={this.state.dropdownOpenManagePG} toggle={() => this.toggleNav('ManagePG')}>
                                            <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                Product Groups
                                            </DropdownToggle>
                                            <DropdownMenu style={styles.noBorder}>
                                                {MasterContext.hasNavigationPermission('Manage > Product Groups > Search') ?
                                                    <DropdownItem><NavLink tag={Link} to="/manageproductgroupssearch"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_search')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Product Groups > Search') ?
                                                    <DropdownItem><NavLink tag={Link} to="/manageproductgroupscreate"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_create')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Product Groups > Import Groups') ?
                                                    <DropdownItem><NavLink tag={Link} to="/manageproductgroupsimport"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_import')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Product Groups > Export Groups') ?
                                                    <DropdownItem><NavLink tag={Link} to="/manageproductgroupsexport"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_product_groups_export')}</span></NavLink></DropdownItem>
                                                    : null}
                                            </DropdownMenu>
                                        </Dropdown>
                                        : null}
                                    {MasterContext.hasNavigationParentPermission('Manage > Location Groups') ?
                                        <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('ManageLG')} onMouseLeave={()=>this.onMouseLeaveNav('ManageLG')} isOpen={this.state.dropdownOpenManageLG} toggle={()=>this.toggleNav('ManageLG')}>
                                            <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                Location Groups
                                            </DropdownToggle>
                                            <DropdownMenu style={styles.noBorder}>
                                                {MasterContext.hasNavigationPermission('Manage > Location Groups > Search') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managelocationgroupssearch"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_search')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Location Groups > Create') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managelocationgroupscreate"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_create')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Location Groups > Import Groups') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managelocationgroupsimport"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_import')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Location Groups > Import Groups') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managelocationgroupsexport"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_location_groups_export')}</span></NavLink></DropdownItem>
                                                    : null}
                                            </DropdownMenu>
                                        </Dropdown>
                                        : null}
                                    {MasterContext.hasNavigationParentPermission('Manage > Scenarios') ?
                                        <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('ManageS')} onMouseLeave={()=>this.onMouseLeaveNav('ManageS')} isOpen={this.state.dropdownOpenManageS} toggle={()=>this.toggleNav('ManageS')}>
                                            <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                Scenarios
                                            </DropdownToggle>
                                            <DropdownMenu style={styles.noBorder}>
                                                {MasterContext.hasNavigationPermission('Manage > Scenarios > Manage') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managescenariosmanage"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_scenarios_manage')}</span></NavLink></DropdownItem>
                                                    : null}
                                                {MasterContext.hasNavigationPermission('Manage > Scenarions > Create') ?
                                                    <DropdownItem><NavLink tag={Link} to="/managescenarioscreate"><span className="dropdown-item">{MasterContext.translate('nav_menu_manage_scenarios_create')}</span></NavLink></DropdownItem>
                                                    : null}
                                                
                                            </DropdownMenu>
                                        </Dropdown>
                                        : null}
                                    {MasterContext.hasNavigationPermission('Manage > Dimensions') ?
                                        <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/managedimensions"><span className="dropdown-item" > {MasterContext.translate('nav_menu_manage_manage_dimensions')}</span></NavLink></DropdownItem>
                                        : null}
                                    </DropdownMenu>
                            </Dropdown>
                            {MasterContext.hasNavigationParentPermission('Regular')
                                && MasterContext.QueryModule('regular') ?
                                <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Regular')} onMouseLeave={()=>this.onMouseLeaveNav('Regular')} isOpen={this.state.dropdownOpenRegular} toggle={()=>this.toggleNav('Regular')}>
                                    <DropdownToggle nav caret>
                                        <span style={styles.navbarNavText}>Regular</span>
                                    </DropdownToggle>
                                    <DropdownMenu left style={styles.alignLeft}>
                                        {MasterContext.hasNavigationPermission('Regular > Executive Summary') ?
                                            <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/executivesummary"><span className="dropdown-item" > {MasterContext.translate('nav_menu_regular_executive_summary')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Regular > Price Review')
                                            && MasterContext.QueryModule('regular')
                                            && MasterContext.QueryModule('price review') ?
                                            <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/pricereview"><span className="dropdown-item" > {MasterContext.translate('nav_menu_regular_price_review')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Regular > Rules Explorer') ?
                                            <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/rulesexplorer"><span className="dropdown-item" > {MasterContext.translate('nav_menu_regular_rules_explorer')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Regular > State Minimum Editor') ?
                                            <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/stateminimum"><span className="dropdown-item" > {MasterContext.translate('nav_menu_regular_state_minimum')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Regular > Buydown Explorer') ?
                                            <DropdownItem style={styles.alignLeft}><NavLink tag={Link} to="/buydowns"><span className="dropdown-item" > {MasterContext.translate('nav_menu_regular_buydown')}</span></NavLink></DropdownItem>
                                            : null}
                                    </DropdownMenu>
                                </Dropdown>
                                : null}
                            {MasterContext.hasNavigationParentPermission('Promotions')
                                && MasterContext.QueryModule('promotion') ?
                                <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Promo')} onMouseLeave={()=>this.onMouseLeaveNav('Promo')} isOpen={this.state.dropdownOpenPromo} toggle={()=>this.toggleNav('Promo')}>
                                    <DropdownToggle nav caret>
                                        <span style={styles.navbarNavText}>Promotions</span>
                                    </DropdownToggle>
                                    <DropdownMenu left style={styles.minWidth}>
                                        {MasterContext.hasNavigationParentPermission('Promotions > Events') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('PromEvent')} onMouseLeave={()=>this.onMouseLeaveNav('PromEvent')} isOpen={this.state.dropdownOpenPromEvent} toggle={()=>this.toggleNav('PromEvent')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Events
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Promotions > Events > Manage') ?
                                                        <DropdownItem><NavLink tag={Link} to="/promotionseventsmanage"><span className="dropdown-item">{MasterContext.translate('nav_menu_promotions_events_manage')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Promotions > Events > Create') ?
                                                        <DropdownItem><NavLink tag={Link} to="/promotionseventcreate"><span className="dropdown-item">{MasterContext.translate('nav_menu_promotions_events_create')}</span></NavLink></DropdownItem>
                                                        : null}                                                    
                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                        {MasterContext.hasNavigationParentPermission('Promotions > Templates') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('PromTemp')} onMouseLeave={()=>this.onMouseLeaveNav('PromTemp')} isOpen={this.state.dropdownOpenPromTemp} toggle={()=>this.toggleNav('PromTemp')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Templates
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Promotions > Templates > Manage') ?
                                                        <DropdownItem><NavLink tag={Link} to="/promotionstemplatesmanage"><span className="dropdown-item">{MasterContext.translate('nav_menu_promotions_templates_manage')}</span></NavLink></DropdownItem>
                                                        : null}

                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                        {MasterContext.hasNavigationParentPermission('Promotions > Ad Sets') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('PromAd')} onMouseLeave={()=>this.onMouseLeaveNav('PromAd')} isOpen={this.state.dropdownOpenPromAd} toggle={()=>this.toggleNav('PromAd')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Ad Sets
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Promotions > Ad Sets > Manage') ?
                                                        <DropdownItem><NavLink tag={Link} to="/promotionsadsetsmanage"><span className="dropdown-item">{MasterContext.translate('nav_menu_promotions_ad_sets_manage')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Promotions > Ad Sets > Create') ?
                                                        <DropdownItem><NavLink tag={Link} to="/promotionsadsetcreate"><span className="dropdown-item">{MasterContext.translate('nav_menu_promotions_ad_sets_create')}</span></NavLink></DropdownItem>
                                                        : null}
                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                    </DropdownMenu>
                                </Dropdown>
                                : null}
                            {MasterContext.hasNavigationParentPermission('Engine') ?
                                <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Engine')} onMouseLeave={()=>this.onMouseLeaveNav('Engine')} isOpen={this.state.dropdownOpenEngine} toggle={()=>this.toggleNav('Engine')}>
                                    <DropdownToggle nav caret>
                                        <span style={styles.navbarNavText}>Engine</span>
                                    </DropdownToggle>
                                    <DropdownMenu left style={styles.alignLeft}>
                                        {MasterContext.hasNavigationPermission('Engine > Run') ?
                                            <DropdownItem><NavLink tag={Link} to="/enginerun"><span className="dropdown-item" > {MasterContext.translate('nav_menu_engine_run')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Engine > Job Status') ?
                                            <DropdownItem><NavLink tag={Link} to="/enginejobstatus"><span className="dropdown-item" > {MasterContext.translate('nav_menu_engine_job_status')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Engine > Task Status') ?
                                            <DropdownItem><NavLink tag={Link} to="/enginetaskstatus"><span className="dropdown-item" > {MasterContext.translate('nav_menu_engine_task_status')}</span></NavLink></DropdownItem>
                                            : null}
                                       
                                    </DropdownMenu>
                                </Dropdown>
                                : null}
                            {MasterContext.hasNavigationParentPermission('Reports') ?
                                <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Report')} onMouseLeave={()=>this.onMouseLeaveNav('Report')} isOpen={this.state.dropdownOpenReport} toggle={()=>this.toggleNav('Report')}>
                                    <DropdownToggle nav caret>
                                        <span style={styles.navbarNavText}>Reports</span>
                                    </DropdownToggle>
                                    <DropdownMenu left style={styles.alignLeft}>
                                        {this.getReports()}
                                    </DropdownMenu>
                                </Dropdown>
                                : null}
                            {MasterContext.hasNavigationParentPermission('Admin') ?
                                <Dropdown direction="down" style={styles.alignLeft} onMouseOver={() => this.onMouseEnterNav('Admin')} onMouseLeave={() => this.onMouseLeaveNav('Admin')} isOpen={this.state.dropdownOpenAdmin} toggle={() => this.toggleNav('Admin')}>
                                    <DropdownToggle nav caret>
                                        <span style={styles.navbarNavText}>Admin</span>
                                    </DropdownToggle>
                                    <DropdownMenu left style={styles.alignLeft}>
                                        {MasterContext.hasNavigationParentPermission('Admin > Settings') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('AdminSet')} onMouseLeave={()=>this.onMouseLeaveNav('AdminSet')} isOpen={this.state.dropdownOpenAdminSet} toggle={()=>this.toggleNav('AdminSet')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Settings
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Admin > Settings > Manage Grids') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminsettingsmanagegrids"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_settings_manage_grids')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Admin > Settings > Lock Days') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminsettingslockdays"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_settings_lock_days')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Admin > Settings > Price Change Days') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminpricechangedays"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_price_change_date_days')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Admin > Settings > Future Buydown Days') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminfuturebuydowndays"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_future_buydown_days')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    <DropdownItem><NavLink tag={Link} to="/topfilteroptions"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_top_filter_options')}</span></NavLink></DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                        {MasterContext.hasNavigationParentPermission('Admin > Users & Roles') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('AdminUser')} onMouseLeave={()=>this.onMouseLeaveNav('AdminUser')} isOpen={this.state.dropdownOpenAdminUser} toggle={()=>this.toggleNav('AdminUser')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Users & Roles
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Users') ?
                                                        <DropdownItem><NavLink tag={Link} to="/users"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_users')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Admin > Users & Roles > Manage Roles') ?
                                                        <DropdownItem><NavLink tag={Link} to="/roles"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_roles')}</span></NavLink></DropdownItem>
                                                        : null}
                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                        {MasterContext.hasNavigationParentPermission('Admin > Audits') ?
                                            <Dropdown direction="right" onMouseOver={()=>this.onMouseEnterNav('AdminAudit')} onMouseLeave={()=>this.onMouseLeaveNav('AdminAudit')} isOpen={this.state.dropdownOpenAdminAudit} toggle={()=>this.toggleNav('AdminAudit')}>
                                                <DropdownToggle caret style={styles.nobackgroundAlignCenter}>
                                                    Audits
                                                </DropdownToggle>
                                                <DropdownMenu style={styles.noBorder}>
                                                    {MasterContext.hasNavigationPermission('Admin > Audits > Audit History') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminaudithistory"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_audit_history')}</span></NavLink></DropdownItem>
                                                        : null}
                                                    {MasterContext.hasNavigationPermission('Admin > Audits > User History') ?
                                                        <DropdownItem><NavLink tag={Link} to="/adminaudit"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_audit_history_admin')}</span></NavLink></DropdownItem>
                                                        : null}
                                                </DropdownMenu>
                                            </Dropdown>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Admin > Manage Reports Menu') ?
                                            <DropdownItem><NavLink tag={Link} to="/adminmanagereportsmenu"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_reports_menu')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Admin > Manage Node Servers') ?
                                            <DropdownItem><NavLink tag={Link} to="/adminmanagenodeservers"><span className="dropdown-item">{MasterContext.translate('nav_menu_admin_manage_node_servers')}</span></NavLink></DropdownItem>
                                            : null}
                                        {MasterContext.hasNavigationPermission('Manage Department Notifications') ?
                                            <DropdownItem><NavLink tag={Link} to="/managedepartmentnotifications"><span className="dropdown-item">Manage Department Notifications</span></NavLink></DropdownItem>
                                            : null}
                                    </DropdownMenu>
                                </Dropdown>
                                : null}    
                            <Dropdown direction="down" onMouseOver={()=>this.onMouseEnterNav('Setting')} onMouseLeave={()=>this.onMouseLeaveNav('Setting')} isOpen={this.state.dropdownOpenSetting} toggle={()=>this.toggleNav('Setting')}>
                                <DropdownToggle nav caret>
                                    <span style={styles.navbarNavText}>Settings</span>
                                </DropdownToggle>
                                <DropdownMenu left style={styles.alignLeft}>
                                        <DropdownItem><NavLink tag={Link} to="/help"><span className="dropdown-item" > Help & Support</span></NavLink></DropdownItem>
                                        <DropdownItem><NavLink tag={Link} to="/userprofile"><span className="dropdown-item" > {MasterContext.translate('nav_menu_user_profile')}</span></NavLink></DropdownItem>
                                        <DropdownItem><NavLink tag={Link} to="/changepassword"><span className="dropdown-item" > {MasterContext.translate('nav_menu_change_password')}</span></NavLink></DropdownItem>
                                        <DropdownItem><NavLink tag={Link} to="/logout"><span className="dropdown-item" > {MasterContext.translate('nav_menu_logout')}</span></NavLink></DropdownItem>
                                    </DropdownMenu>
                            </Dropdown>
                            <NavLink tag={Link} to="/filemanager"><span style={styles.navbarNavText} >File Manager</span></NavLink>
                            <NavbarText className="ml-auto">Hello, {this.getUserName()}!</NavbarText>
                            </Nav>                        
                        </Collapse>
                    </Navbar>                
                
                <div style={{ clear: 'both' }}></div>

                <div style={styles.containerMinHeight}>
                    <Container fluid={true}>
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}